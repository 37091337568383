import React from 'react';
import styled from 'styled-components';
import Colors from '../Colors';
import * as t from '../Typography';
import { Content } from './Layout';
import { media } from '../MediaQueries';
import { FaHeart } from 'react-icons/fa';

const FooterWrapper = styled.div`
  background: ${Colors.grey};
  padding: ${props => (props.big ? '210px' : props.medium ? '100px' : '60px')} 0 60px 0;
  ${media.tablet`padding: ${props => (props.big ? '150px' : props.medium ? '100px' : '128px')} 20px 50px 20px;`}
`;

const Copyright = styled.p`
  text-align: center;
  letter-spacing: 0.7px;
  color: ${Colors.darkGrey_2};

  & p:nth-child(2) {
    font-size: 13px;
    margin-left: 12px;
    margin-top: 10px;
  }
`;

const Footer = ({ big, medium }) => (
  <FooterWrapper {...(big && { big })} {...(medium && { medium })}>
    <Content>
      <Copyright>
        <t.P noMargin>© Chanwhe Kim 2019</t.P>
        <t.P noMargin>
          Handmade with <FaHeart size={14} color="rgb(255, 92, 87)" />.
        </t.P>
      </Copyright>
    </Content>
  </FooterWrapper>
);

export default Footer;
